<template lang='pug'>
	.safe-driving
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/home.jpg')
				h1.title Safety on roads.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Listen to or read the most important rules to keep in mind while driving in Iceland
		.listen-section
			ListenExpanded
		//- .section-pad
		//- 	BookBanner(
		//- 		:bannerNumber="3"
		//- 	)

		.constrainer.section-pad
			BlueCarRentalBanner(
				:image="{ url: require('@/assets/bluecarrental/image3.jpg') }"
				text="Not sure where to book a rental car for your trip? We encourage you to do it with Blue Rental Car. Find more about their offers here."
			)
		.constrainer
			//- AD Safety on roads.
			//- .space-safe-one.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 4' :id='659')
			//-.space-safe-one.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 400' :id='673')
		.constrainer.section-pad
			DrivingTest(text='Think you’ve got it covered? Test your knowledge before you head out.')
		//- .constrainer.section-pad
		//- 	BlueCarRentalBanner(
		//- 		:image="{ url: require('@/assets/bluecarrental/image3.jpg') }"
		//- 		text="Not sure where to book a rental car for your trip? We encourage you to do it with Blue Rental Car. Find more about their offers here."
		//- 	)
		.safety-tips.section-pad.constrainer.gutter
			h1.title Safety tips.
			.dashes
				.dash(v-for='n in 5')
			.para.fs Iceland is a beautiful country, filled with majestic landscapes and mythical nature. The best way to experience it is by heading to the open road and letting it carry you into the wasteland. But driving in Iceland can be dangerous, especially to people unfamiliar driving in rural, harsh terrain, with snow, rain or ice on the road. Here are some general safety tips to help you get back home safe.
		.questions.gutter.constrainer
			.question(v-for='q, i in questions' :class='{ active: q.active }')
				.q.clickable(@click='viewQuestion(i)')
					.text {{ q.fields.question }}
					.sign-container
						.sign
							.one(:class='{ active: q.active }')
							.two
				.a(:class='{ active: q.active, animated: q.animated }')
					p(v-for='answer in q.fields.answer') {{ answer }}
		.constrainer.section-pad
			.section-pad.margin-bottom
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			//- AD Safety on roads.
			//- .space-safe-two.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 5' :id='660')
			//-.space-safe-two.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 500' :id='682')
			.between
			FAQSection(question1='Where can I find the most up to date information about weather and road conditions?' question2='What happens if I hit a sheep?' question3='A rock cracked my windshield. Now what?')
		//- .constrainer.section-pad
		//- 	BlueCarRentalBanner(
		//- 		:image="{ url: require('@/assets/bluecarrental/image4.jpg') }"
		//- 		text-align="left"
		//- 	)
</template>

<script>
export default {
	name: 'SafeDriving',
	metaInfo: {
		title: 'Safe.is - Safety on roads.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/drive-safely/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/home.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Safety on roads.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Get safety tips, test your driving knowledge and listen to important safety information before you start driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Get safety tips, test your driving knowledge and listen to important safety information before you start driving in Iceland.'
			}
		]
	},
	data: function () {
		return {
			questions: []
		};
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/safetyTips').then((result) => {
			this.questions = result.data;
		});
	},
	methods: {
		viewQuestion: function (index) {
			for (let i = 0; i < this.questions.length; i++) {
				if (i === index) {
					this.questions[i].active = !this.questions[i].active;
					if (this.questions[i].active) {
						setTimeout(() => {
							this.questions[i].animated = true;
						}, 100);
					} else {
						this.questions[i].animated = true;
					}
				} else {
					this.questions[i].active = false;
					this.questions[i].animated = false;
				}
			}
		}
	}
};
</script>

<style scoped lang='less'>
.margin-bottom {
	margin-bottom: 50px;
}
.safe-driving {
	.space-safe-one {
		min-height: 1px;
		&.main-mobile {
			display: none;
			margin-top: 48px;
			margin-bottom: -48px;
		}
		&.main-desktop {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		@media screen and (max-width: 768px) {
			&.main-mobile {
				display: block;
			}
			&.main-desktop {
				// display: none;
			}
		}
	}
	.space-safe-two {
		&.main-mobile {
			display: none;
			margin-top: 48px;
		}
		&.main-desktop {
			margin-top: 96px;
			margin-bottom: 96px;
		}
		@media screen and (max-width: 768px) {
			&.main-mobile {
				display: block;
			}
			&.main-desktop {
				// display: none;
			}
		}
	}
	.questions {
		.question {
			transition: .3s;
			border-bottom: 1px solid black;
			&.active {
				background-color: rgba(0,0,0,0);
				border-bottom: 1px solid rgba(0,0,0,0);
			}
			.q {
				margin-top: 8px;
				padding-top: 25px;
				padding-bottom: 17px;
				margin-bottom: 8px;
				display: grid;
				grid-template-columns: 1fr 11px;
				grid-column-gap: 20px;
				border-left: 4px solid rgba(0,0,0,0);
				transition-delay: .2s;
				transition: .3s;
				.text {
					transition: .3s;
					text-align: left;
					height: 48px;
					font-family: Montserrat;
					font-size: 24px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.5;
					letter-spacing: 2px;
					color: #000000;
					@media screen and (max-width: 1220px) {
						height: auto;
						font-size: 18px;
						line-height: 24px;
					}
				}
				.sign-container {
					height: 100%;
					position: relative;
					.sign {
						display: flex;
						justify-self: center;
						position: relative;
						height: 18px;
						.one {
							position: absolute;
							left: 50%;
							transition: .3s;
							transform: translate3d(-50%, 0px, 0px) rotateX(0deg);
							&.active {
								transform: translate3d(-50%, 0px, 0px) rotateX(90deg);
							}
							height: 18px;
							width: 2px;
							background-color: #F9DB49;
						}
						.two {
							left: -3px;
							position: absolute;
							top: 50%;
							transform: translate3d(0px, -50%, 0px);
							width: 18px;
							height: 2px;
							background-color: #F9DB49;
						}
					}
				}
			}
			.a {
				pointer-events: none;
				transition: .3s;
				display: none;
				opacity: 0;
				padding-left: 6px;
				padding-bottom: 20px;
				margin-top: 18px;
				text-align: left;
				color: #4D4D4D;
				font-size: 15px;
				letter-spacing: 0.6px;
				line-height: 22px;
				font-family: Montserrat;
				font-size: 18px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.62;
				letter-spacing: 1.8px;
				@media screen and (max-width: 1220px) {
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 1.33px;
				}
				&.active {
					transform: rotate3d(1, 0, 0, 90deg);
					display: block;
				}
				&.animated {
					opacity: 1;
					transform: rotate3d(1, 0, 0, 0deg);
				}
			}
			&:hover {
				.q {
					border-left: 4px solid rgba(0, 71, 110, .8);
					.text {
						transform: translate3d(15px, 0px, 0px);
					}
				}
				&.active {
					.q {
						border-left: 4px solid rgba(0, 0, 0, 0);
						.text {
							transform: translate3d(0px, 0px, 0px);
						}
					}
				}
			}
		}
	}
	.safety-tips {
		@media screen and (max-width: 768px) {
			padding-top: 36px;
		}
		.para {
			margin-top: 48px;
			text-align: left;
			max-width: 910px;
			height: 240px;
			@media screen and (max-width: 1220px) {
				height: auto;
			}
		}
		.title {
			text-align: left;
			max-width: 800px;
			height: 72px;
		}
		.dashes {
			margin-top: 48px;
			@media screen and (max-width: 768px) {
				margin-top: 12px;
			}
		}
	}
	.header {
		height: 628px;
		max-height: 628px;
		background-image: url('~@/assets/jpg/hero.jpg');
		background-size: cover;
		background-position: 50% 100%;
		.header-image {
			display: none;
		}
		@media screen and (max-width: 1220px) {
			background-image: none;
			height: initial;
			max-height: initial;
			.header-image {
				margin-top: 48px;
				display: block;
				width: 100%;
				max-height: 440px;
				object-fit: cover;
				object-position: bottom;
			}
		}
		.content {
			position: relative;
			top: 0px;
			height: 100%;
			left: 0px;
			text-align: left;
			.title {
				padding-top: 68px;
				@media screen and (max-width: 768px) {
					padding-top: 40px;
				}
				position: relative;
				max-width: 374px;
			}
			.para {
				padding-top: 38px;
				max-width: 374px;
			}
			.button {
				display: grid;
				grid-template-columns: 80% 20%;
				height: 48px;
				width: 260px;
				background-color: #FFFFFF;
				box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
				border-left: 4px solid #AF7595;
				margin-top: 58px;
				.text {
					color: black;
					height: 30px;
					width: 161px;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: 1.5px;
					line-height: 48px;
					padding-left: 32px;
				}
				.arrow {
					position: relative;
					top: 50%;
					transform: translate3d(0px, -50%, 0px);
				}
			}
		}
	}
	.dashes {
		margin-top: 38px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
	.between {
		@media screen and (max-width: 768px) {
			height: 60px;
		}
	}
}
</style>
